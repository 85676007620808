import React, { useEffect } from "react"
import Achievement from "../components/Achievement";
import UserCard from "../components/UserCard";
import { useDispatch, useSelector } from "react-redux";
import { readVipData } from "../modules/firebase";
import { useLocation } from "react-router-dom";
import { searchParam } from "../util/util";
import { useState } from "react";

const BoardPage = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState()
  const [name, setName] = useState()
  const fbState = useSelector((state) => state.firebase);
  const { search } = useLocation()
  useEffect(() => {
    const paramEmail = searchParam(search, 'email')
    const paramName = searchParam(search, 'name')
    setEmail(paramEmail)
    setName(paramName)
    dispatch(readVipData({ email: paramEmail }))
  }, []);

  return (
    <>
      {!fbState.loading ?
        <div className="flex flex-col justify-center items-center p-2">
          <UserCard active={fbState.member.state} email={email} name={name} level={fbState.member.level} generation={fbState.member.generation} inactiveAt={fbState.member.inactiveAt} />
          <Achievement list={fbState.member.achievement} />
        </div>
        :
        <>
          <div className="flex flex-col justify-center items-center grayscale  blur-sm">
            <UserCard active={false} email={''} name={''} level={1} generation={0} />
            <Achievement list={false} />
          </div>
          <div className="fixed left-0 right-0 -top-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full text-center sm:p-0 ">
              <div className="relative p-16  rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div className="bg-blue-100 border-t-4 border-blue-500 rounded text-blue-900 px-4 py-3 shadow-md" role="alert">
                  <div className="flex">
                    <div className="py-1"><svg className="fill-current h-6 w-6 text-blue-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Loading...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>}
    </>
  );
}


export default React.memo(BoardPage);