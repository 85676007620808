import React from "react"
import AchievementIcon from "../atom/AchievementIcon"
import s20221 from "../icon/s1-1.jpg"
import s20222 from "../icon/s2-1.jpg"
import s20223 from "../icon/s3-1.jpg"
import s20224 from "../icon/s4-1.jpg"
import s20231 from "../icon/2023-s1.jpg"
import s20232 from "../icon/2023-s2.jpg"
import s20233 from "../icon/2023-s3.jpg"
import s20234 from "../icon/2023-s4.jpg"
import moment from "moment"
import AchievementRateIcon from "../atom/AchievementRateIcon"

const Achievement = ({ list }) => {
  return (
    <>

      <div className="mt-4 text-gray-600 font-bold text-xl text-center">
        <span>업적</span>
      </div>
      {(moment(new Date()).isoWeeks() >= 34) ?
        <div className="grid grid-cols-2 sm:grid-cols-4 sm:gap-8 gap-4 py-2">
          <AchievementRateIcon title={'2022 봄'} icon={s20221} percent={list.s20221} disable={!(list.s20221 >= 1 || list.s20221 === "open")} rate={list.s20221} />
          <AchievementRateIcon title={'2022 여름'} icon={s20222} percent={list.s20222} disable={!(list.s20222 >= 1 || list.s20222 === "open")} rate={list.s20222} />
          <AchievementRateIcon title={'2022 가을'} icon={s20223} percent={list.s20223} disable={!(list.s20223 >= 1 || list.s20223 === "open")} rate={list.s20223} />
          <AchievementRateIcon title={'2022 겨울'} icon={s20224} percent={list.s20223} disable={!(list.s20224 >= 1 || list.s20224 === "open")} rate={list.s20224} />
          <AchievementRateIcon title={'2023 봄'} icon={s20231} percent={list.s20231} disable={!(list.s20231 >= 1 || list.s20231 === "open")} rate={list.s20231} />
          <AchievementRateIcon title={'2023 여름'} icon={s20232} percent={list.s20232} disable={!((list.s20232 >= 1 || list.s20232 === "open") && (moment(new Date()).isoWeeks() >= 34))} rate={list.s20232} />
          <AchievementRateIcon title={'2023 가을'} icon={s20233} percent={list.s20233} disable={!(list.s20233 >= 1 || list.s20233 === "open")} rate={list.s20233} />
          <AchievementRateIcon title={'2023 겨울'} icon={s20234} percent={list.s20233} disable={!(list.s20234 >= 1 || list.s20234 === "open")} rate={list.s20234} />
        </div>
        :
        <div className="grid grid-cols-2 sm:grid-cols-4 sm:gap-8 gap-4 py-2">
          <AchievementIcon title={'2022 봄'} icon={s20221} percent={list.s20221} disable={!(list.s20221 >= 1 || list.s20221 === "open")} />
          <AchievementIcon title={'2022 여름'} icon={s20222} percent={list.s20222} disable={!(list.s20222 >= 1 || list.s20222 === "open")} />
          <AchievementIcon title={'2022 가을'} icon={s20223} percent={list.s20223} disable={!(list.s20223 >= 1 || list.s20223 === "open")} />
          <AchievementIcon title={'2022 겨울'} icon={s20224} percent={list.s20223} disable={!(list.s20224 >= 1 || list.s20224 === "open")} />
          <AchievementIcon title={'2023 봄'} icon={s20231} percent={list.s20231} disable={!(list.s20231 >= 1 || list.s20231 === "open")} />
          <AchievementIcon title={'2023 여름'} icon={s20232} percent={list.s20232} disable={!((list.s20232 >= 1 || list.s20232 === "open") && (moment(new Date()).isoWeeks() >= 34))} />
          <AchievementIcon title={'2023 가을'} icon={s20233} percent={list.s20233} disable={!(list.s20233 >= 1 || list.s20233 === "open")} />
          <AchievementIcon title={'2023 겨울'} icon={s20234} percent={list.s20233} disable={!(list.s20234 >= 1 || list.s20234 === "open")} />
        </div>
      }
    </>
  );
}


export default React.memo(Achievement);