import { getFirestore, getDocs, collection, getDoc, setDoc, doc, arrayUnion, arrayRemove, updateDoc } from "firebase/firestore";

class FirebaseRepository {
  constructor(app) {
    this.store = getFirestore(app);
  }

  async updateUser(challenger, week, data) {
    try {
      await setDoc(doc(this.store, "challenger", challenger, "challenge", `${week}`), data)
      console.log("Document written", data);
    } catch (e) {
      console.error("Error adding document: ", e);
    }


    try {

      if (data.total >= 7) {
        await updateDoc(doc(this.store, "total", `${week}`), {
          one: arrayUnion(challenger),
          seventy: arrayUnion(challenger),
          hundred: arrayUnion(challenger),
        })
      }
      else if (data.total >= 5) {
        await updateDoc(doc(this.store, "total", `${week}`), {
          one: arrayUnion(challenger),
          seventy: arrayUnion(challenger),
          hundred: arrayRemove(challenger),
        })
      }
      else if (data.total >= 1) {
        await updateDoc(doc(this.store, "total", `${week}`), {
          one: arrayUnion(challenger),
          seventy: arrayRemove(challenger),
          hundred: arrayRemove(challenger),
        })
      }
      else if (data.total <= 0) {
        await updateDoc(doc(this.store, "total", `${week}`), {
          one: arrayRemove(challenger),
          seventy: arrayRemove(challenger),
          hundred: arrayRemove(challenger),
        })
      }

    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  async readStore(challenger) {
    const settingRef = doc(this.store, "setting", "challenge");
    const settingSnapshot = await getDoc(settingRef);
    console.log('settingSnapshot.data()', settingSnapshot.data())

    const totalRef = collection(this.store, "total");
    const totalSnapshot = await getDocs(totalRef);
    console.log('totalSnapshot.data()', totalSnapshot)

    var totalData = new Object();
    totalSnapshot.forEach((doc) => {
      totalData[doc.id] = {
        one: doc.data().one,
        seventy: doc.data().seventy,
        hundred: doc.data().hundred,
      }
    });
    console.log(totalData)

    return { "startWeek": settingSnapshot.data().startWeek, "userCnt": settingSnapshot.data().challengerCnt, "totalData": totalData }
  }

  async readVipStore(email) {
    console.log(email)
    const membershipRef = doc(this.store, "membership", email);
    console.log(membershipRef)
    const membershipSnapshot = await getDoc(membershipRef);

    if (membershipSnapshot.exists()) {
      return membershipSnapshot.data()
    } else {
      return false
    }
  }

}

export default FirebaseRepository;
