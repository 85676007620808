
import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import MyPage from './page/MyPage';

const App = () => {
  return (
    <div >
      <HashRouter >
        <Routes>
          <Route path="/mypage" element={<MyPage />} />
        </Routes>
      </HashRouter >
    </div >
  );
}



export default React.memo(App);
