import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import rootReducer from './modules';

if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

ReactDOM.render(
  <Provider store={rootReducer}>
    <App />
  </Provider>,
  document.getElementById('root')
);
