

import React from "react";

const AchievementRateIcon = ({ disable, icon, title, rate = 0 }) => {

  var circumference = Math.PI * 2 * 40; // 원의 둘레 (반지름: 24, 테두리 두께: 2)
  var progressOffset = disable ? Math.PI * 2 * 40 : circumference * (100 - rate) / 100; // 진행 상태에 따른 원의 둘레 위치 계산
  console.log(circumference, progressOffset)

  return (
    <>
      <div className={`flex flex-col justify-center text-center w-24 items-center border rounded-md shadow ${disable ? "text-gray-400" : "text-gray-600"} hover:text-gray-600`}>
        <span className={`bg-gray-100 w-full font-bold shadow text-sm px-2 py-x rounded-md`}>
          {title}
        </span>

        <div class="flex items-center justify-center my-3">
          <div className="relative overflow-hidden shadow rounded-full mx-auto bg-white" >
            {disable ?
              <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed z-10">
                <div className="flex justify-center items-center h-full">
                  <div className="text-4xl text-gray-200">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 z-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg>
                  </div>
                </div>
              </div> : <></>}
            {disable ?
              <img className="w-[4.5rem] h-[4.5rem] brightness-50 blur-sm z-0" src={icon} alt="achievement icon" /> :
              <img className="w-[4.5rem] h-[4.5rem]" src={icon} alt="achievement icon" />}
          </div>
          <svg class="absolute w-24 h-24 -rotate-90" style={{ filter: "drop-shadow(-1px 0px 1px rgba(30, 30, 30, 0.1))" }}>
            <circle className="stroke-[5px] fill-transparent stroke-gray-200" cx="50%" cy="50%" r="40"></circle>
            <circle className="stroke-[5px] fill-transparent stroke-yellow-400" cx="50%" cy="50%" r="40" stroke-linecap="round"
              style={{ strokeDasharray: `${circumference}`, strokeDashoffset: `${progressOffset}` }}></circle>
          </svg>
        </div>

        <div className="border-t w-full" />

        <span className={`uppercase text-sm font-bold`}>
          {disable ? "locked" : rate === "open" ? `open` : `${Math.floor(rate)}%`}
        </span>
      </div>

    </>
  );
}

export default AchievementRateIcon;