import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FirebaseRepository from '../service/firebase_repository';
import FirebaseStorage from '../service/firebase_storage';
import { firebaseApp } from '../service/firebase_config';
import { firebaseAppSub } from '../service/firebase_config_sub';

const firebaseRepository = new FirebaseRepository(firebaseApp);
const firebaseStorage = new FirebaseStorage(firebaseApp);

const firebaseRepositorySub = new FirebaseRepository(firebaseAppSub);
const firebaseStorageSub = new FirebaseStorage(firebaseAppSub);

const initialState = {
  loading: true,
  signData: '',
  signState: false,
  totalData: 0,
  weekData: 0,
  userCnt: 0,
  week: 0,
  startWeek: 20,
  camp: [],
};

// sub 코드
export const readVipData = createAsyncThunk(
  'firebase/membership/read',
  async ({ email: email }) => {
    console.log('membershipVipRead')
    const response = await firebaseRepository.readVipStore(email)
    console.log("response", response)
    return response
  }
)
//main 코드
export const updateUser = createAsyncThunk(
  'firebase/user',
  async ({ user: user, week: week, data: data }) => {
    console.log(user, week, data)
    const response = await firebaseRepository.updateUser(user, week, data)
  }
)

export const readData = createAsyncThunk(
  'firebase/setting/week',
  async () => {
    console.log('readStartWeek')
    const response = await firebaseRepository.readStore()
    return response
  }
)

export const readCamp = createAsyncThunk(
  'firebase/camp',
  async () => {
    const response = await fetch("https://firebasestorage.googleapis.com/v0/b/xingmove-2dce5.appspot.com/o/camp.json?alt=media", {
      method: "GET"
    })
      .then((response) => response.json())

    console.log('response');
    console.log(response);

    return response
  }
)

export const uploadCamp = createAsyncThunk(
  'firebase/camp/upload',
  async ({ file: file }) => {
    firebaseStorage.uploadStorage(file)
    return
  }
)
export const firebaseRTK = createSlice({
  name: 'firebase',
  initialState,
  reducers: {},
  extraReducers: {
    [updateUser.fulfilled.type]: (state) => {
      console.log('updateUser', state)
    },
    [readData.pending.type]: (state) => {
      state.loading = true;
    },
    [readData.fulfilled.type]: (state, action) => {
      console.log('readData', action, action.payload)
      state.startWeek = action.payload.startWeek
      state.totalData = action.payload.totalData
      state.userCnt = action.payload.userCnt
      console.log(state.startWeek, state.weekData)
      state.loading = false;
    },
    [readCamp.pending.type]: (state) => {
      state.loading = true;
    },
    [readCamp.fulfilled.type]: (state, action) => {
      console.log('readData', action, action.payload)
      state.camp = action.payload
      console.log(state.camp)
      state.loading = false;
    },
    [uploadCamp.fulfilled.type]: (state) => {
      console.log('uploadCamp', state)
    },
    [readVipData.pending.type]: (state) => {
      state.loading = true;
    },
    [readVipData.fulfilled.type]: (state, action) => {
      console.log('readVipData', action, action.payload)
      if (!action.payload) {
        state.member = {
          "achievement": {
          },
          "inactiveAt": null,
          "generation": 0,
          "lastAt": null,
          "level": 1,
          "email": "",
          "createAt": "",
          "name": "",
          "state": "엑티브"
        }
      } else {
        state.member = action.payload
      }
      console.log('state', state.member)
      state.loading = false;
    },
  }
})

export const { } = firebaseRTK.actions
