

import React from "react";

const AchievementIcon = ({ disable, icon, title }) => {
  return (
    <>
      <div className="flex flex-col justify-center text-center">
        <span className="bg-gray-100 md:w-24 w-20 text-gray-500 mb-2 shadow text-sm font-medium px-2 py-x rounded-md ">
          {title}
        </span>

        <div className="relative md:w-24 w-20 overflow-hidden border-solid border-4 shadow border-gray rounded-full mx-auto bg-white" >
          {disable ?
            <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed z-10">
              <div className="flex justify-center items-center h-full">
                <div className="text-4xl text-gray-200">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 z-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                  </svg>
                </div>
              </div>
            </div> : <></>}
          {disable ?
            <img className="brightness-50 blur-sm z-0" src={icon} alt="achievement icon" /> :
            <img className=" z-0" src={icon} alt="achievement icon" />}
        </div>
      </div>
    </>
  );
}

export default AchievementIcon;