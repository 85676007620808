import React from "react"
import ActiveChip from "../atom/ActiveChip";
import LevelIcon from "../atom/LevelIcon";
import LevelLine from "../atom/LevelLine";

const UserCard = ({ active, name, email, level, generation, inactiveAt }) => {
  const settingIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 24 24" fill="currentColor">
    <g>
      <path d="M17.41,6.59L15,5.5l2.41-1.09L18.5,2l1.09,2.41L22,5.5l-2.41,1.09L18.5,9L17.41,6.59z M21.28,12.72L20.5,11l-0.78,1.72 L18,13.5l1.72,0.78L20.5,16l0.78-1.72L23,13.5L21.28,12.72z M16.24,14.37l1.94,1.47l-2.5,4.33l-2.24-0.94 c-0.2,0.13-0.42,0.26-0.64,0.37L12.5,22h-5l-0.3-2.41c-0.22-0.11-0.43-0.23-0.64-0.37l-2.24,0.94l-2.5-4.33l1.94-1.47 C3.75,14.25,3.75,14.12,3.75,14s0-0.25,0.01-0.37l-1.94-1.47l2.5-4.33l2.24,0.94c0.2-0.13,0.42-0.26,0.64-0.37L7.5,6h5l0.3,2.41 c0.22,0.11,0.43,0.23,0.64,0.37l2.24-0.94l2.5,4.33l-1.94,1.47c0.01,0.12,0.01,0.24,0.01,0.37S16.25,14.25,16.24,14.37z M13,14 c0-1.66-1.34-3-3-3s-3,1.34-3,3s1.34,3,3,3S13,15.66,13,14z" />
    </g>
  </svg>
  const rocketIcon =
    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 24 24" fill="currentColor">
      <g>
        <path d="M12,2.5c0,0,4.5,2.04,4.5,10.5c0,2.49-1.04,5.57-1.6,7H9.1c-0.56-1.43-1.6-4.51-1.6-7C7.5,4.54,12,2.5,12,2.5z M14,11 c0-1.1-0.9-2-2-2s-2,0.9-2,2s0.9,2,2,2S14,12.1,14,11z M7.69,20.52c-0.48-1.23-1.52-4.17-1.67-6.87l-1.13,0.75 C4.33,14.78,4,15.4,4,16.07V22L7.69,20.52z M20,22v-5.93c0-0.67-0.33-1.29-0.89-1.66l-1.13-0.75c-0.15,2.69-1.2,5.64-1.67,6.87 L20,22z" />
      </g>
    </svg>
  const launchIcon =
    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 24 24" fill="currentColor">
      <g>
        <g>
          <path d="M9.19,6.35c-2.04,2.29-3.44,5.58-3.57,5.89L2,10.69l4.05-4.05c0.47-0.47,1.15-0.68,1.81-0.55L9.19,6.35L9.19,6.35z M11.17,17c0,0,3.74-1.55,5.89-3.7c5.4-5.4,4.5-9.62,4.21-10.57c-0.95-0.3-5.17-1.19-10.57,4.21C8.55,9.09,7,12.83,7,12.83 L11.17,17z M17.65,14.81c-2.29,2.04-5.58,3.44-5.89,3.57L13.31,22l4.05-4.05c0.47-0.47,0.68-1.15,0.55-1.81L17.65,14.81 L17.65,14.81z M9,18c0,0.83-0.34,1.58-0.88,2.12C6.94,21.3,2,22,2,22s0.7-4.94,1.88-6.12C4.42,15.34,5.17,15,6,15 C7.66,15,9,16.34,9,18z M13,9c0-1.1,0.9-2,2-2s2,0.9,2,2s-0.9,2-2,2S13,10.1,13,9z" />
        </g>
      </g>
    </svg>
  const checkIcon =
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 48 48" stroke="currentColor" stroke-width="2">
      <path d="M20.95 31.95 35.4 17.5l-2.15-2.15-12.3 12.3L15 21.7l-2.15 2.15ZM9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30ZM9 9v30V9Z" />
    </svg>
  const uncheckIcon =
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 48 48" stroke="currentColor" stroke-width="2">
      <path d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30Z" />
    </svg>

  return (
    <>
      {/* <div className="bg-white shadow-lg rounded-md max-w-screen-sm w-full z-20 mt-2">
        <div className="relative" >
          <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed z-10">
            <div className="flex justify-center items-center h-full">
              <div className="text-4xl text-gray-200">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 z-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="brightness-50 justify-center w-full rounded-t-md px-4 py-2 text-white bg-red-400 font-semibold text-lg flex align-center cursor-pointer active:bg-gray-300 transition duration-300 ease">
            그라운드 교육자
          </div>
        </div>
      </div>
      <div className="bg-white shadow-md rounded-md max-w-screen-sm w-full z-10">
        <div className="px-4 py-2 justify-center text-white bg-emerald-500 font-semibold text-lg flex align-center w-full cursor-pointer active:bg-gray-300 transition duration-300 ease">
          멤버 과정 수료자
        </div>
      </div> */}
      <div className="bg-white shadow-md rounded-md p-5 max-w-screen-sm w-11/12 z-0">
        {/* <!-- {"액티브"} --> */}
        {/* <ActiveChip active={active} /> */}
        {/* <div className=" border-b border-gray-300 my-2" /> */}
        {/* <!-- {"유저"} --> */}
        <div className="flex flex-col mb-4">
          <span className="text-base text-gray-500">{generation}기</span>
          <span className="text-3xl font-bold">{name}</span>
          <span className="text-base text-gray-500">{email}</span>
        </div>
        <div className=" border-b border-gray-300 mb-2" />
        <div className="flex flex-row font-semibold text-sm text-gray-600 mb-4">
            멤버십 기간 {inactiveAt == null || (typeof inactiveAt === 'string' && inactiveAt.trim() === '') ? " " : `${inactiveAt.substring(0, 4)-1}${inactiveAt.slice(4)} ~ ${inactiveAt}`}
        </div>
        {/* <!-- {"멤버 교육자"} --> */}
        <button type="button" className="inline-block w-36 mb-2 px-4 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out">
          싱무브 VIP 멤버
        </button>
        <div className="flex flex-col mb-8">
          <div className="flex flex-row font-semibold text-sm text-gray-600">
            {checkIcon}
            6주 멤버되기 캠프 수료
          </div>
          <div className="flex flex-row font-semibold text-sm text-gray-400 line-through">
            {uncheckIcon}
            싱무브 교육자 자격
          </div>
        </div>
        {/* <!-- {"레벨"} --> */}
        <button type="button" className="inline-block w-36 px-4 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out">
          Level
        </button>
        <div className="py-6">
          <div className="flex items-center">
            <LevelIcon state={level === 1 ? 'enabled' : 'keep'} icon={settingIcon} text={'초급자'} />
            <LevelLine disabled={!(level >= 2)} />
            <LevelIcon state={level === 1 ? 'disabled' : level === 2 ? 'enabled' : 'keep'} icon={rocketIcon} text={'중급자'} />
            <LevelLine disabled={!(level === 3)} />
            <LevelIcon state={level === 3 ? 'enabled' : 'disabled'} icon={launchIcon} text={'상급자'} />
          </div>
        </div>
      </div>
    </>
  );
}


export default React.memo(UserCard);
